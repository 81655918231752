@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

body {
  background-color: #fafbfc;
  font-family: Poppins, sans-serif !important;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar:horizontal {
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:horizontal {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/*---------------------------------------------------*/
/* fonts starts here */

legend {
  /* font-size: 0.85em !important; */
  color: #757575 !important;
}
.MuiFormLabel-asterisk,
.MuiInputLabel-asterisk {
  color: red;
}
.MuiSelect-select:focus {
  background-color: unset !important;
}

.MuiTabs-root {
  min-height: 48px !important;
}
.leftTabnew .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  background-color: rgba(51, 90, 255, 0.05);
  border-radius: 3%;
  color: rgb(51, 90, 255) !important;
}
.leftTabnew {
  width: calc(100% - 24px);
}
.leftTabnew .css-10d9dml-MuiTabs-indicator {
  display: none;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  letter-spacing: normal !important;
}
header.MuiAppBar-root {
  background-color: white;
  color: black;
  max-height: 48px;
  box-shadow: rgb(0 0 0 / 12%) 0rem 0.125rem 0.125rem !important;
}
.css-10ltm04-MuiToolbar-root {
  min-height: 60px !important;
}
.MuiTabPanel-root {
  padding: 12px !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.MuiDataGrid-root .MuiDataGrid-cell:focus,
.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: solid #3f51b5 0px !important;
}
.MuiTablePagination-selectLabel{
  display: none !important;
}
/* .MuiTabs-indicator {
  background-color: #fff !important;
} */
.checkbox-cross {
  line-height: 0.6em;
  background-color: gray;
  border-radius: 3px;
  color: white;
  width: 0.75em;
  height: 0.75em;
  margin: 3px;
}

.display-center {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none !important;
}
.display-center:focus-visible {
  outline: none !important;
}
.list-popover .list-item {
  width: 100%;
  height: 48px;
  cursor: pointer;
}

.list-popover .list-item .list-item-icon {
  min-width: 2rem !important;
  color: inherit;
}
/* 
.list-item {
  position: relative;
}
.list-item::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 7.5px;
  background-color: black;
  top: 8px;
  left: 7px;
} */
.dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.table-unknown {
  max-height: 350px;
  max-width: 99%;
  margin-bottom: 5px;
}

.table-unknown-tr {
  cursor: pointer;
  transition: 1s;
}

.table-unknown-tr:hover() {
  background-color: black;
  transition: 1s;
}

.table-unknown::-webkit-scrollbar {
  width: 5px;
}

.table-unknown::-webkit-scrollbar-track {
  display: none;
}

.table-unknown::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.363);
  border-radius: 15px;
}

/*sahud*/
.cursor-pointer {
  cursor: pointer!important;
}
.top-70px {
  top: 70px !important;
}
.b-0 {
  border: 0px !important;
}
.mt-120 {
  margin-top: 120px !important;
}
.mt-1 {
  margin-top: 1vh !important;
}


.m-0 {
  margin: 0% !important;
}
.p-0 {
  padding: 0% !important;
}
.mp-0 {
  margin: 0px !important;
  padding: 0px !important;
}
.w-100 {
  width: 100% !important;
}
.w-50 {
  width: 50% !important;
}
.h-100 {
  height: 100% !important;
}
.h-inherit {
  height: inherit;
}
.h-100-vh {
  height: 100vh;
}
.wh-100 {
  height: 100% !important;
  width: 100% !important;
}
.mb-30 {
  margin-bottom: 20px;
}
.mt-20 {
  margin-top: 20px!important;
}
.start-flex {
  display: flex!important;
  justify-content: flex-start!important;
  align-items: flex-start!important;
}
.flex-between-start {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.flex-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.navigationList {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
}
.flex-start-end {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}
.flex-between-end {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.flex-center-start {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.flex-center {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.flex-column {
  flex-direction: column!important;
}
.wrap-flex {
  flex-wrap: wrap;
}
.flex-end-center {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.flex-end-start {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
.menumage {
  margin: 0px 15px 0px 15px !important;
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  line-height: 1.125 !important;
  letter-spacing: 0px !important;
  color: inherit;
  height: 60px;
  text-transform: capitalize !important;
  border-radius: 0px !important;
  padding: 0px !important;
  border-bottom: 2px solid #fff !important;
}
.menumage:hover {
  background-color: #fff !important;
  color: #335aff !important;
  border-bottom: 2px solid #335aff !important;
}
.addNew {
  background-color: #335aff !important;
  text-transform: capitalize !important;
  height: 35px !important;
  white-space: nowrap;
}

.flex-start-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}


.search-component {
  width: 210px;
  height: 35px;
  background: #ffffff;
  border: 1px solid #ebecf0;
  border-radius: 3px;
  padding: 2px 12px;
  cursor: pointer;
}

.search-component .icon {
  font-size: 18px!important;
  color: #172b4d!important;
  margin-top: 0px !important;
}
.input-search {
  border: 0px !important;
  outline: none !important;

}

.floatRight {
  float: right !important;
}

.h-100 {
  height: 100% !important;
}

.navigationArea {
  width: 350px!important;
  height: 100vh!important;
  border-right: 1px solid #ebecf0!important;
}

.link-navigateList {
  width: 100%!important;
  text-decoration: none!important;
  display: flex!important;
  padding: 0px 12px 0px 54px !important;
}

.navigationList {
  width: 100%!important;
  height: 50px!important;
}
.collapse-button {
  width: 100%!important;
  padding: 0px 12px!important;
  height: auto!important;
}
.navigationArea-1 {
  width: 250px!important;
  height: calc(100vh - 120px)!important;
  border-right: 1px solid #ebecf0!important;
}

#leave-table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
}

#leave-table td,
#leave-table th {
  border: 1px solid #ddd;
  padding: 15px;
  min-width: 165px;
}

#leave-table th {
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: left;
  color: black;
}

#salary {
  border-collapse: collapse;
  width: 100%;
}

#salary td, #salary  {
  border: 1px solid #ddd;
  padding: 20px;
}


#salary tr:hover {background-color: #ddd;}

#salary th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
}


.custom-button {
  height: 35px;
  padding: 2px 12px 2px 14px !important;
  border-color: #ebecf0 !important;
}

#salary2 {
  border-collapse: collapse;
  width: 100%;
}

#salary2 td, #salary2  {
  border: 1px solid #ddd;
  padding: 20px;
}

#salary2 th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
}



.timeline-container {
  font-family: "Roboto", sans-serif;
  max-width: 800px;
  margin: 0 auto;
  border-left: 1px dashed #bdbdbd;
  padding-left: 25px;
  margin-left: 130px;
}

.timeline-container h2 {
  font-family: "Pacifico", cursive;
  font-size: 32px;
  margin: 0;
  color: #00272b;
  border-bottom: 1px solid #eee;
}

.timeline-container .event-title {
  padding: 20px;
  margin: 20px 0;
  margin-top: -5px;
  border-radius: 4px;
  line-height: 2;
  cursor: pointer;
  position: relative;
  transition: all 500ms;
}

.timeline-container .event-title::before {
  content: "";
  width: 50px;
  height: 50px;
  position: absolute;
  left: -50px;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  transition: all 500ms;
}
.timeline-container .event {
  padding: 25px;
  background-color: #f5f7ff;
  margin: 10px 0;
  margin-top: -30px;
  border-radius: 4px;
  line-height: 2;
  cursor: pointer;
  position: relative;
  transition: all 500ms;
}

.timeline-container .event::before {
  content: "";
  width: 50px;
  height: 50px;
  position: absolute;
  left: -79px;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  transition: all 500ms;
}

.timeline-container .event-title .event-date {
  position: absolute;
  left: -150px;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 400px;
}

.event-date {
  display: inline-flex;
  align-items: center;
}

@media (max-width: 700px) {
  .timeline-container {
    max-width: 100%;
    margin-left: 8px;
  }

  .timeline-container .event-title .event-date {
    position: static;
    margin-bottom: -20px;
  }
}
